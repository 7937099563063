import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  ScrollView,
  LayoutChangeEvent,
} from 'react-native';
import {getCasinoList, getCasinoUrl, getCasinoType} from '../home.service';
import {CasinoGameItem, CasinoListParams, CasinoTypeItem} from '../home.type';
import NoData from '@/components/basic/error-pages/no-data';
import globalStore from '@/services/global.state';
import {goTo, navigateTo} from '@/utils';
import LazyImage from '@/components/basic/image';

const HomeCasino: React.FC = () => {
  const [scrollViewWidth, setScrollViewWidth] = useState(0); // 新增状态计算宽度
  const [tabs, setTabs] = useState<CasinoTypeItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [data, setData] = useState<CasinoGameItem[]>([]);
  const [loading, setLoading] = useState(true);
  // 保存每个 tab 的布局信息
  const [tabLayouts, setTabLayouts] = useState<{
    [key: string]: {x: number; width: number};
  }>({});

  // ScrollView ref 用于滚动
  const scrollViewRef = useRef<ScrollView>(null);

  // 使用 useCallback 包裹 fetchData，使其引用稳定
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params: CasinoListParams = {pageNo: 1, gameType: selectedTab};
      const res = await getCasinoList(params);
      if (
        res?.getCasionGamesVoList &&
        Array.isArray(res.getCasionGamesVoList) &&
        res.getCasionGamesVoList.length > 0
      ) {
        setData(res.getCasionGamesVoList);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching casino list', error);
    } finally {
      setLoading(false);
    }
  }, [selectedTab]);

  // 根据选中的tab获取数据
  useEffect(() => {
    if (selectedTab) {
      fetchData();
      const layout = tabLayouts[selectedTab];
      if (layout && scrollViewRef.current && scrollViewWidth > 0) {
        const tabCenterX = layout.x + layout.width / 2;
        const targetOffsetX = tabCenterX - scrollViewWidth / 2;
        scrollViewRef.current.scrollTo({x: targetOffsetX, animated: true});
      }
    }
  }, [selectedTab, fetchData, tabLayouts, scrollViewWidth]); // 添加依赖

  // 获取tab数据
  useEffect(() => {
    fetchTabs();
  }, []);

  const fetchTabs = async () => {
    try {
      const res = await getCasinoType();
      if (res && Array.isArray(res)) {
        // 过滤掉 name 为 "Live" 的项
        const filteredTabs = res.filter(item => item.name !== 'Live');
        setTabs(filteredTabs);
        // 默认选中第一个 tab（如果存在）
        if (filteredTabs.length > 0) {
          setSelectedTab(filteredTabs[0].name);
        }
      }
    } catch (error) {
      console.error('Error fetching tabs', error);
    }
  };

  const getUrl = async (id: number) => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    const res = await getCasinoUrl(id);
    navigateTo(res);
  };

  const renderItem = ({item}: {item: CasinoGameItem}) => (
    <View style={styles.card}>
      <TouchableOpacity onPress={() => getUrl(item.gameId)}>
        <LazyImage
          imageUrl={item.gamePic}
          width={(globalStore.screenWidth - 42) / 3}
          height={((globalStore.screenWidth - 42) / 3) * (30 / 23)}
          occupancy="transparent"
          radius={9}
        />
      </TouchableOpacity>
      <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
        {item.gameName}
      </Text>
    </View>
  );

  const onTabLayout = (tabName: string) => (event: LayoutChangeEvent) => {
    const {x, width} = event.nativeEvent.layout;
    setTabLayouts(prev => ({...prev, [tabName]: {x, width}}));
  };

  const renderTab = (tab: CasinoTypeItem, index: number) => {
    const isActive = selectedTab === tab.name;
    const isLast = index === tabs.length - 1;
    return (
      <TouchableOpacity
        key={tab.name}
        onPress={() => !isActive && setSelectedTab(tab.name)}
        onLayout={onTabLayout(tab.name)}
        style={[styles.tabItemContainer, isLast && styles.lastTabItem]}>
        <View style={styles.tabItem}>
          <Image
            source={{uri: isActive ? tab.icon : tab.openIcon}}
            style={styles.tabIcon}
          />
          <Text style={[styles.tabText, isActive && styles.tabTextActive]}>
            {tab.name}
          </Text>
        </View>
        {isActive && <View style={styles.tabIndicator} />}
      </TouchableOpacity>
    );
  };

  if (!loading && data.length === 0) {
    return <NoData />;
  }

  return (
    <View style={styles.container}>
      <View
        style={styles.tabScrollWrapper}
        onLayout={e => setScrollViewWidth(e.nativeEvent.layout.width)}>
        <ScrollView
          ref={scrollViewRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.tabsContainer}>
          {tabs.map((tab, index) => renderTab(tab, index))}
        </ScrollView>
      </View>
      <FlatList
        data={data}
        keyExtractor={item => item.gameId.toString()}
        renderItem={renderItem}
        numColumns={3}
        contentContainerStyle={styles.listContainer}
      />
      {!globalStore.isWeb && (
        <View style={[{width: '100%', height: 150}]}></View>
      )}
    </View>
  );
};

export default HomeCasino;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
  },
  tabScrollWrapper: {
    marginHorizontal: 12, // 限制左右边距
  },
  tabsContainer: {
    paddingVertical: 10,
    backgroundColor: '#fff',
    paddingHorizontal: 0, // 移除水平 padding
  },
  tabItemContainer: {
    marginRight: 20,
    alignItems: 'center',
  },
  tabItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabIcon: {
    width: 14,
    height: 14,
    marginRight: 4,
  },
  tabText: {
    fontSize: 12,
    color: '#666666',
    fontWeight: '600',
  },
  tabTextActive: {
    fontSize: 12,
    color: '#333333',
    fontWeight: '700',
  },
  tabIndicator: {
    marginTop: 6,
    height: 4,
    width: 17,
    backgroundColor: '#00bb8f',
  },
  listContainer: {
    paddingHorizontal: 12,
  },
  card: {
    margin: 3,
    alignItems: 'center',
    alignSelf: 'flex-start', // 让剩余的卡片靠左对齐
    width: (globalStore.screenWidth - 42) / 3, // 确保宽度固定
  },
  name: {
    marginTop: 5,
    color: '#333333',
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'center',
    width: '100%',
  },
  lastTabItem: {
    marginRight: 0, // 最后一个元素无右边距
  },
});
