import React from 'react';
import {
  View,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {goTo} from '@utils';
import globalStore from '@/services/global.state';

// 获取屏幕宽度
const screenWidth = globalStore.screenWidth;

const HomeHotGame: React.FC = () => {
  return (
    <View style={styles.container}>
      {/* 三个盒子 */}
      <View style={styles.boxContainer}>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'dice', params: `configId=16`});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-game-top/home-dice.webp')}
            style={styles.box}></ImageBackground>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'quick3d', params: 'id=103'});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-game-top/home-k3d.webp')}
            style={styles.box}></ImageBackground>
        </TouchableOpacity>
      </View>
      <View style={{alignItems: 'center', marginTop: 10}}>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'color'});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-game-top/home-color.webp')}
            style={styles.bottomImg}></ImageBackground>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    paddingBottom: 14,
  },
  boxContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -14,
    paddingHorizontal: 14,
  },
  box: {
    width: (screenWidth - 37) / 2, // 减去左右边距后均分宽度
    height: ((screenWidth - 37) / 2) * 0.55,
    overflow: 'hidden',
  },
  bottomImg: {
    width: screenWidth - 28, // 减去左右边距后均分宽度
    height: (screenWidth - 28) * 0.31,
  },
});

export default HomeHotGame;
