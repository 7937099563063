import React, {useEffect, useState} from 'react';
import {Image, Modal, TouchableOpacity, StyleSheet} from 'react-native'; //Text
import theme from '@style';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {useIsFocused} from '@react-navigation/native';
import globalStore from '@/services/global.state';

const sampleImage = require('@assets/icons/home/newUserPop.webp'); // 弹出图片路径
const HomePop = (props: {spinShow: () => void}) => {
  const {spinShow} = props;
  const {screenWidth} = useScreenSize();
  const isFocused = useIsFocused(); // 用来追踪屏幕的焦点状态
  // 新增的 state 用于控制弹出图片的显示
  const [isImageVisible, setIsImageVisible] = useState(false);
  // 显示图片
  const showImage = () => {
    setIsImageVisible(true);
  };
  // 关闭图片
  const closeImage = () => {
    setIsImageVisible(false);
    globalStore.isNewUser = 'false';
  };
  // 点击图片
  const onImage = () => {
    closeImage();
    spinShow();
  };
  useEffect(() => {
    if (isFocused) {
      const isNewUser = globalStore.isNewUser;
      if (isNewUser === 'true') {
        showImage();
      }
    }
  }, [isFocused]);

  return (
    <Modal
      transparent={true}
      visible={isImageVisible}
      onRequestClose={closeImage}
      animationType="fade">
      <TouchableOpacity
        style={styles.modalOverlay}
        activeOpacity={1}
        onPressOut={() => {}}
        onPress={onImage}>
        <LazyImage
          imageUrl={sampleImage}
          width={screenWidth * 0.72}
          height={screenWidth * 0.84}
        />
        <TouchableOpacity onPress={closeImage} style={styles.closeButton}>
          <Image
            style={[theme.icon.xxl]}
            source={require('@assets/icons/home/button-close.png')}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  noBankContainer: {
    backgroundColor: '#F6F7FA',
    maxHeight: 120,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    marginTop: 20,
    backgroundColor: 'none',
  },
});

export default HomePop;
