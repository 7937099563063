import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, FlatList, StyleSheet} from 'react-native';
import {getCasinoList, getCasinoUrl} from '../home.service';
import {CasinoGameItem, CasinoListParams} from '../home.type';
import NoData from '@/components/basic/error-pages/no-data';
import globalStore from '@/services/global.state';
import {goTo, navigateTo} from '@/utils';
import LazyImage from '@/components/basic/image';

const HomeCasino: React.FC = () => {
  const [data, setData] = useState<CasinoGameItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // 在调用时传递入参
      const params: CasinoListParams = {pageNo: 1, gameType: 'Fishing'};
      const res = await getCasinoList(params);
      if (
        res?.getCasionGamesVoList &&
        Array.isArray(res.getCasionGamesVoList) &&
        res.getCasionGamesVoList.length > 0
      ) {
        setData(res.getCasionGamesVoList);
      } else {
      }
    } catch (error) {
      console.error('Error', error);
    } finally {
      setLoading(false);
    }
  };

  const getUrl = async (id: number) => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    const res = await getCasinoUrl(id);
    navigateTo(res);
  };

  const renderItem = ({item}: {item: CasinoGameItem}) => (
    <View style={styles.card}>
      <TouchableOpacity onPress={() => getUrl(item.gameId)}>
        <LazyImage
          imageUrl={item.gamePic}
          width={(globalStore.screenWidth - 42) / 3}
          height={((globalStore.screenWidth - 42) / 3) * (30 / 23)}
          occupancy="transparent"
          radius={9}
        />
      </TouchableOpacity>
      <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
        {item.gameName}
      </Text>
    </View>
  );

  if (!loading && data.length === 0) {
    return <NoData />;
  }

  return (
    <View>
      <FlatList
        data={data}
        keyExtractor={item => item.gameId.toString()}
        renderItem={renderItem}
        numColumns={3}
        contentContainerStyle={styles.container}
      />
      {!globalStore.isWeb && (
        <View style={[{width: '100%', height: 150}]}></View>
      )}
    </View>
  );
};

export default HomeCasino;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
  },
  card: {
    margin: 3,
    alignItems: 'center',
    alignSelf: 'flex-start', // 让剩余的卡片靠左对齐
    width: (globalStore.screenWidth - 42) / 3, // 确保宽度固定
  },
  name: {
    marginTop: 5,
    color: '#333333',
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'center',
    width: '100%',
  },
});
