import theme from '@/style';
import React from 'react';
import {View, Animated, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import LinearGradient from '@/components/basic/linear-gradient';
// const gameTabs = ['Dice', 'Color', '3 Digits', 'Kerala', 'Matka'];
const gameTabs = ['3 Digits', 'Kerala', 'Matka'];

const Tabs = ({
  activeTab = 0,
  scrollToCurrentGame = () => {},
}: {
  scrollToCurrentGame: (index: number) => void;
  activeTab: number;
}) => {
  const tabAnim = React.useRef(new Animated.Value(0)).current;
  // const [itemWidth, setItemWidth] = React.useState(0);

  // const outputRange = React.useMemo(() => {
  //   return gameTabs.map((_, i) => i * itemWidth);
  // }, [itemWidth]);

  React.useEffect(() => {
    Animated.timing(tabAnim, {
      toValue: activeTab,
      duration: 120,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{height: 48}}>
      <View style={[theme.fill.fill]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.flex1,
            theme.padding.topxs,
            theme.flex.centerByCol,
            theme.background.white,
          ]}>
          {/* <Image
            style={[theme.icon.l, theme.margin.rights]}
            source={require('@assets/imgs/home/ball.webp')}
          /> */}
          <LinearGradient
            colors={['#9cedcd', '#ffffff']}
            style={[
              theme.flex.row,
              theme.flex.flex1,
              theme.flex.between,
              {
                height: 45,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                paddingLeft: 3,
                paddingRight: 3,
              },
            ]}
            // onLayout={e => {
            //   setItemWidth(e.nativeEvent.layout.width / gameTabs.length);
            // }}
          >
            {gameTabs.map((_, i) => (
              <NativeTouchableOpacity
                onPress={() => scrollToCurrentGame(i)}
                key={i}
                style={[
                  theme.flex.center,
                  activeTab === i ? styles.ontab : styles.intab,
                ]}>
                <Text
                  size="medium"
                  color={activeTab === i ? '#333333' : '#666666'}
                  blod>
                  {_}
                </Text>
                <View style={[activeTab === i ? styles.ontabline : {}]}></View>
              </NativeTouchableOpacity>
            ))}
          </LinearGradient>
        </View>
      </View>
      {/* <Animated.View
        style={[
          {width: itemWidth},
          styles.lineContainer,
          {
            transform: [
              {
                translateX: tabAnim.interpolate({
                  inputRange: gameTabs.map((i, index) => index),
                  outputRange: outputRange,
                }),
              },
            ],
          },
        ]}>
        <View style={[styles.line]} />
      </Animated.View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  // lineContainer: {
  //   position: 'absolute',
  //   bottom: 6,
  //   left: 44,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  // line: {
  //   ...theme.background.primary,
  //   height: 4,
  //   width: 20,
  // },
  ontab: {
    width: 123,
    height: 42,
    backgroundColor: '#ffffff',
    borderTopRightRadius: 9,
    borderTopLeftRadius: 9,
    marginTop: 3,
  },
  intab: {
    width: 123,
    height: 42,
  },
  ontabline: {
    width: 30,
    height: 4,
    backgroundColor: '#00bb8f',
    borderRadius: 12.5,
    marginTop: 4,
  },
});

export default Tabs;
