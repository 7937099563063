import React from 'react';
import {View} from 'react-native';
// import HomeDice from './components/dice/home-dice';
import {
  DiceListItem,
  DigitListItem,
  KeralaListItem,
  MatkaListItem,
} from './home.type';
// import HomeDigits from './components/digit/home-digits';
// import HomeColor from './components/home-color';
import HomeKerala from './components/kerala/home-kerala';
import HomeMatka from './components/matka/home-matka';
import HomeDigitOff from './components/digit-off/home-digit-off';
// import HomeDigitSto from './components/digit-stock/home-digit-stock';
// import HomeCar from './components/car/home-car';
import theme from '@style';

export interface HomeTabListContentProps {
  diceList: DiceListItem[];
  digitList: DigitListItem[];
  keralaList: KeralaListItem[];
  matkaList: MatkaListItem[];
  onMeasure: (index: number, anchor: number) => void;
}

const HomeTabListContent = ({
  onMeasure = () => {},
  // diceList = [],
  // digitList = [],
  keralaList = [],
  matkaList = [],
}: HomeTabListContentProps) => {
  const dataContent = [
    // {
    //   name: 'Dice',
    //   content: <HomeDice diceList={diceList} />,
    // },
    // {
    //   name: 'Color',
    //   content: <HomeColor />,
    // },
    {
      name: '3Digits',
      // content: <HomeDigits digitList={digitList} />,
      content: <HomeDigitOff />,
    },
    {
      name: 'Kerala',
      content: <HomeKerala keralaList={keralaList} />,
    },
    {
      name: 'Matka',
      content: <HomeMatka matkaList={matkaList} />,
    },
  ];

  return (
    <View style={[theme.padding.lrl, theme.background.white]}>
      {/* <HomeDigitSto /> */}
      {dataContent.map((_, i) => (
        <View
          key={i}
          onLayout={e => {
            onMeasure(i, e.nativeEvent.layout.height);
          }}>
          {_.content}
        </View>
      ))}
    </View>
  );
};

export default HomeTabListContent;
