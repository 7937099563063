/* eslint-disable */
/* prettier-ignore */
import {http, indusWinHttp} from '@utils';
import {
  ScratchListItem,
  NoticeCheckList,
  RankingList,
  PagerParams,
  SpinOrderList,
  OrderCreateParams,
  PrizeInfo,
  FreeLotteryInfo,
  BannerListItem,
  HotGameItem,
  KeralaListItem,
  DigitListItem,
  DiceListItem,
  MatkaListItem,
  LiveHotGameItem,
  LiveGameListParams,
  HomeGameFloorItem,
  HomeGameParams,
  LiveGamePageInfo,
  CarPageInfo,
  DigitOffListItem,
  CasinoListResponse,
  CasinoListParams,
  CasinoTypeItem,
} from './home.type';

/** 获取刮刮乐入口列表 */
export const getScratchList = () => {
  return indusWinHttp.get<null, ScratchListItem[]>(
    'iGaming/api/scratchoffGameList',
  );
};

/** 获取活动入口 */
export const getNoticeCheck = () => {
  return http.post<null, NoticeCheckList>('app/sys/notice/check');
};

/** 顶部banner */
export const getBannerList = (type = 2) => {
  return http.post<{type: number}, BannerListItem[]>('app/banner/manage/list', {
    type,
  });
};

/** 热门小游戏 */
export const getHotGgmeList = () => {
  return http.post<null, HotGameItem[]>('app/hotGame/list');
};

/** kerala列表 */
export const getKeralaList = () => {
  return http.post<{type: number}, KeralaListItem[]>(
    'app/lottery/type/kerala/list/new',
    // TODO 这里的渠道是临时解决,需要后端调整逻辑
    {type: 1, channel: 'h5'},
  );
};

/** 3Digit列表 */
export const getDigitList = () => {
  return http.post<null, DigitListItem[]>('app/pick/info/list');
};

/** Dice列表 */
export const getDiceList = () => {
  return http.post<null, DiceListItem[]>('app/diceThree/homeList');
};

/** 3D Official Lottery列表 */
export const getDigitOffList = (digitsType: number) => {
  return http.post<{ digitsType: number }, DigitOffListItem[]>('app/digits/info/list', { digitsType });
};

/** Matka列表 */
export const getMatkaList = () => {
  return http.post<null, MatkaListItem[]>('app/matka/lottery/home/listV2');
};

/** Home板块游戏列表，目前暂时不使用 */
export const getHomeGames = () => {
  return http.post<HomeGameParams, HomeGameFloorItem[]>(
    'app/homeGamesCategory/getAllListBySort',
    {
      position: 1,
    },
  );
};

export const postRankingList = () => {
  return http.post<null, RankingList>('app/turntable/order/ranking/list');
};

export const postSpinOrderList = (params: PagerParams) => {
  return http.post<PagerParams, SpinOrderList>(
    'app/turntable/order/list',
    params,
  );
};

export const postSpinOrderCreate = (count: number) => {
  return http.post<OrderCreateParams, PrizeInfo>('app/turntable/order/create', {
    isDemo: 1,
    count,
    betAmount: 10 * count,
  });
};

export const postGetFreeLottery = () => {
  return http.post<null, FreeLotteryInfo>('app/share/getFreeKeralaTickets');
};

export const getLiveHotGameList = () => {
  return indusWinHttp.get<null, LiveHotGameItem[]>('iGaming/liveHotGameList');
};

/**
 * 首页视讯游戏列表
 * @param gameName 游戏名称（筛选）
 * @param sort 可不传 0 desc 1 asc
 */
export function getLiveGameList() {
  return http.post<LiveGameListParams, LiveGamePageInfo>(
    'app/homeGames/getList',
    {
      categoryId: 6, // 目前依然使用固定分类id
    },
  );
}

/**
 * 首页视讯游戏列表
 * @param gameName 游戏名称（筛选）
 * @param sort 可不传 0 desc 1 asc
 */
export function getCarGameList() {
  return http.post<LiveGameListParams, CarPageInfo>('app/homeGames/getList', {
    categoryId: 15, // car
  });
}

// 所有消息提醒
export const getAllRemind = () => {
  return http.post<null, number>('app/message/all/remind');
};

/** fish,casino,live列表 */
export const getCasinoList = (params: CasinoListParams) => {
  return http.post<CasinoListParams, CasinoListResponse>(
    'app/casion/list',
    params
  );
};

/** fish,casino,live获取游戏链接 */
export function getCasinoUrl(id: number) {
  return http.post<{ id: number }, string>('iGaming/js/login/' + id);
}

/** sport获取游戏链接 */
export function getSportUrl() {
  return http.post<null, string>('iGaming/js/login/sport/');
}

/** casino获取游戏类型列表 */
export const getCasinoType = () => {
  return http.post<null, CasinoTypeItem[]>('app/casion/game/type/list');
};
